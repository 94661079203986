export function getGapBetweenRanges(array1: number[], array2: number[]) {
  const a = [array1[0], array1[array1.length - 1]]
  const b = [array2[0], array2[array2.length - 1]]
  if (a[0] <= a[1] && a[1] < b[0]) {
    return b[0] - a[1]
  }
  if (b[0] <= b[1] && b[1] < a[0]) {
    return b[1] - a[0]
  }
  return 0
}
